enifed('@ember/map/index', ['exports', 'ember-babel', '@ember/debug', 'ember-utils', '@ember/map/lib/ordered-set', '@ember/map/lib/utils'], function (exports, _emberBabel, _debug, _emberUtils, _orderedSet, _utils) {
  'use strict';

  var Map = function () {
    function Map() {
      (0, _emberBabel.classCallCheck)(this, Map);
      (true && !(false) && (0, _debug.deprecate)('Use of @ember/Map is deprecated. Please use native `Map` instead', false, {
        id: 'ember-map-deprecation',
        until: '3.5.0'
      }));


      this._keys = new _orderedSet.default();
      this._values = Object.create(null);
      this.size = 0;
    }

    /**
      @method create
      @static
      @private
    */


    Map.create = function create() {
      var Constructor = this;
      return new Constructor();
    };

    Map.prototype.get = function get(key) {
      if (this.size === 0) {
        return;
      }

      var values = this._values;
      var guid = (0, _emberUtils.guidFor)(key);

      return values[guid];
    };

    Map.prototype.set = function set(key, value) {
      var keys = this._keys;
      var values = this._values;
      var guid = (0, _emberUtils.guidFor)(key);

      // ensure we don't store -0
      var k = key === -0 ? 0 : key; // eslint-disable-line no-compare-neg-zero

      keys.add(k, guid);

      values[guid] = value;

      this.size = keys.size;

      return this;
    };

    Map.prototype.delete = function _delete(key) {
      if (this.size === 0) {
        return false;
      }
      // don't use ES6 "delete" because it will be annoying
      // to use in browsers that are not ES6 friendly;
      var keys = this._keys;
      var values = this._values;
      var guid = (0, _emberUtils.guidFor)(key);

      if (keys.delete(key, guid)) {
        delete values[guid];
        this.size = keys.size;
        return true;
      } else {
        return false;
      }
    };

    Map.prototype.has = function has(key) {
      return this._keys.has(key);
    };

    Map.prototype.forEach = function forEach(callback /*, ...thisArg*/) {
      (true && !(typeof callback === 'function') && (0, _debug.assert)(Object.prototype.toString.call(callback) + ' is not a function', typeof callback === 'function'));


      if (this.size === 0) {
        return;
      }

      var map = this;
      var cb = void 0,
          thisArg = void 0;

      if (arguments.length === 2) {
        thisArg = arguments[1];
        cb = function (key) {
          return callback.call(thisArg, map.get(key), key, map);
        };
      } else {
        cb = function (key) {
          return callback(map.get(key), key, map);
        };
      }

      this._keys.forEach(cb);
    };

    Map.prototype.clear = function clear() {
      this._keys.clear();
      this._values = Object.create(null);
      this.size = 0;
    };

    Map.prototype.copy = function copy() {
      return (0, _utils.copyMap)(this, new Map());
    };

    return Map;
  }();

  exports.default = Map;
});