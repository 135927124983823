enifed('ember-views/lib/component_lookup', ['exports', '@ember/debug', '@ember/string', 'ember-runtime'], function (exports, _debug, _string, _emberRuntime) {
  'use strict';

  exports.default = _emberRuntime.Object.extend({
    componentFor: function (_name, owner, options) {
      (true && !(_name.indexOf('-') > -1 || false /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */ && _name.charAt(0) === _name.charAt(0).toUpperCase()) && (0, _debug.assert)('You cannot use \'' + _name + '\' as a component name. Component names must contain a hyphen' + (false /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */ ? ' or start with a capital letter' : '') + '.', _name.indexOf('-') > -1 || false && _name.charAt(0) === _name.charAt(0).toUpperCase()));

      var name = false /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */ && _name.charAt(0) === _name.charAt(0).toUpperCase() ? (0, _string.dasherize)(_name) : _name;
      var fullName = 'component:' + name;
      return owner.factoryFor(fullName, options);
    },
    layoutFor: function (_name, owner, options) {
      (true && !(_name.indexOf('-') > -1 || false /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */ && _name.charAt(0) === _name.charAt(0).toUpperCase()) && (0, _debug.assert)('You cannot use \'' + _name + '\' as a component name. Component names must contain a hyphen.', _name.indexOf('-') > -1 || false && _name.charAt(0) === _name.charAt(0).toUpperCase()));


      var name = false /* EMBER_GLIMMER_ANGLE_BRACKET_INVOCATION */ && _name.charAt(0) === _name.charAt(0).toUpperCase() ? (0, _string.dasherize)(_name) : _name;
      var templateFullName = 'template:components/' + name;
      return owner.lookup(templateFullName, options);
    }
  });
});