enifed('@ember/map/with-default', ['exports', 'ember-babel', '@ember/debug', '@ember/map/index', '@ember/map/lib/utils'], function (exports, _emberBabel, _debug, _index, _utils) {
  'use strict';

  var MapWithDefault = function (_Map) {
    (0, _emberBabel.inherits)(MapWithDefault, _Map);

    function MapWithDefault(options) {
      (0, _emberBabel.classCallCheck)(this, MapWithDefault);
      (true && !(false) && (0, _debug.deprecate)('Use of @ember/MapWithDefault is deprecated. Please use native `Map` instead', false, {
        id: 'ember-map-deprecation',
        until: '3.5.0'
      }));

      var _this = (0, _emberBabel.possibleConstructorReturn)(this, _Map.call(this));

      _this.defaultValue = options.defaultValue;
      return _this;
    }

    /**
      @method create
      @static
      @param [options]
        @param {*} [options.defaultValue]
      @return {MapWithDefault|Map} If options are passed, returns
        `MapWithDefault` otherwise returns `EmberMap`
      @private
    */


    MapWithDefault.create = function create(options) {
      if (options) {
        return new MapWithDefault(options);
      } else {
        return new _index.default();
      }
    };

    MapWithDefault.prototype.get = function get(key) {
      var hasValue = this.has(key);

      if (hasValue) {
        return _Map.prototype.get.call(this, key);
      } else {
        var defaultValue = this.defaultValue(key);
        this.set(key, defaultValue);
        return defaultValue;
      }
    };

    MapWithDefault.prototype.copy = function copy() {
      var Constructor = this.constructor;
      return (0, _utils.copyMap)(this, new Constructor({
        defaultValue: this.defaultValue
      }));
    };

    return MapWithDefault;
  }(_index.default);

  exports.default = MapWithDefault;
});